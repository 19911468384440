import React, { useCallback, useRef, useState, forwardRef, useImperativeHandle, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./ImmunizationTdapView.module.css";

import { AddButton, InputBox } from "@cpnw/ui";
import DashboardRadio from "components/DashboardRadio";
import FormDatePicker from "components/FormDatePicker";

const SubmissionTypes = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  Military: "Military",
};

const SubmissionTypeDescriptions = {
  A: "A. Vaccination Dates:",
  B: "B. Immunity by Titer:",
  Military: "Military immunization",
};

const ImmunizationTdapView = forwardRef(({
  data = {},
  onChange,
  disabled = false,
  onValidate }, ref) => {

    const [error, setError] = useState('');

      useImperativeHandle(ref, () => ({
        validate: () => {
          const errors = {};
          if (!data.date1 && !data.date2) {
            errors.dateField = 'At least one of the date fields is required';
            setError(errors.dateField);
            onValidate(errors.dateField);
            return errors; // Return error to parent
          }
          setError('');
          onValidate({});
          return null; // No errors
        },
      }));

  const setValue = (key, value) => {
    if (key) {
      data[key] = value;
    }

    if (onChange) {
      onChange({ ...data });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.testRow}>
          A. Initial Tdap date {" "}
          <FormDatePicker
            dateFormat="MM/dd/yyyy"
            dateString={data.date1}
            setDate={(value) => {
              data.date1 = value;
              setValue();
            }}
            disabled={disabled}
          />
        </div>
        <div className={styles.testRow}>
          B. Td/Tdap subsequent dose date {" "}
          <FormDatePicker
            dateFormat="MM/dd/yyyy"
            dateString={data.date2}
            setDate={(value) => {
              data.date2 = value;
              setValue();
            }}
            disabled={disabled}
          />
        </div>
        {error && <div className={styles.errorMessages}>{error}</div>}
      </div>
    </>
  );
})

export default ImmunizationTdapView;

import React from 'react';
import { useState, useEffect } from "react";
import PropTypes from "prop-types"
import classNames from 'classnames';
import styles from "./PassportAdminReviewTable.module.css";
import PassportAdminReviewItem from "../PassportAdminReviewItem";
import { Button, HeaderSection, InputBox } from "@cpnw/ui";
import DashboardTablePagination from 'components/DashboardTablePagination';

export default function PassportAdminReviewTable({ items, onItemView = () => {} }) {
  const [colWidths] = useState({
    nameWidth: 200,
    idWidth: 150,
    schoolWidth: 300,
    programWidth: 200,
    statusWidth: 120,
    viewWidth: 70,
  });
  const totalWidth = Object.values(colWidths).reduce((acc, width) => acc + width, 0);
  const [data, setData] = useState(items);
  const [filterSchool, setFilterSchool] = useState('');
  const [filterProgram, setFilterProgram] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterSearch, setFilterSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  function handleClick(event) {
  }

  // Handle pagination change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    let filteredResult = handleFilter(filterSchool, filterProgram, filterStatus, filterSearch);
    setCurrentPage(1);
    setData(filteredResult);
  }, [filterSchool, filterProgram, filterStatus, filterSearch, items]);

  const handleFilter = (school, program, status, search) => {
    return items.filter(item => {

      const itemName = item.name?.toLowerCase();
      const itemId = item.sid?.toLowerCase();
      const itemSchool = item.school?.toLowerCase();
      const itemProgram = item.program?.toLowerCase();
      const itemStatus = item.reviewStatus? item.reviewStatus?.toLowerCase() : '';

      if (school && !itemSchool.includes(school.toLowerCase())) {
        return false;
      }

      if (program && !itemProgram.includes(program.toLowerCase())) {
        return false;
      }

      if (status && !itemStatus.includes(status.toLowerCase())) {
        return false;
      }

      if ((search && !itemName.includes(search.toLowerCase()))
        && (search && !itemId.includes(search.toLowerCase()))
        && (search && !itemSchool.includes(search.toLowerCase()))
        && (search && !itemProgram.includes(search.toLowerCase()))
        && (search && !itemStatus.includes(search.toLowerCase()))) {
        return false;
      }
      return true;
    });
  };

  const renderItems = () => {
    const startIndex = (currentPage - 1) * limit;
    const endIndex = Math.min(startIndex + limit, data.length);
    const itemsToRender = data.slice(startIndex, endIndex);

    return (
      itemsToRender.length > 0 ? (
        itemsToRender.map((item, index) => {
          return (
            <div key={index} className={classNames(styles.rowItem, (index % 2 === 0) ? undefined : styles.passportAdminReviewTableContentEven)}>
              <PassportAdminReviewItem info={item} width={colWidths} onView={() => {
                onItemView(item)
              }}/>
            </div>
          )
        })
      ) : (
        // No data row
        <div className={styles.reviewNoDataCol}>
          <div>No items found.</div>
        </div>
      ))
  };

  return (
    <>
      <div className={styles.passportAdminReviewTableContainer}>
        <HeaderSection header={"Review Submissions"} />
        <div className={styles.passportAdminReviewFilter}>
          <div className={styles.filterCol}>
            <InputBox
              placeholder={"Search School"}
              setValue={(e) => {
                setFilterSchool(e.target.value);
              }}
            />
          </div>
          <div className={styles.filterCol}>
            <InputBox
              placeholder={"Search Program"}
              setValue={(e) => {
                setFilterProgram(e.target.value);
              }}
            />
          </div>
          <div className={styles.filterCol}>
            <InputBox
              placeholder={"Search Status"}
              setValue={(e) => {
                setFilterStatus(e.target.value);
              }}
            />
          </div>
          <div className={styles.filterCol}>
            <InputBox
              placeholder={"Search for anything"}
              setValue={(e) => {
                setFilterSearch(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles.passportAdminReviewTableHeader}>
          <div className={classNames(styles.passportAdminReviewTableHeaderCol)}>
            <span className={styles.textCenter} style={{ width: colWidths.nameWidth + 'px' }}>Name</span>
          </div>
          <div className={classNames(styles.passportAdminReviewTableHeaderCol)}>
            <span style={{ width: colWidths.idWidth + 'px' }}>SID/EID</span>
          </div>
          <div className={classNames(styles.passportAdminReviewTableHeaderCol)}>
            <span className={styles.textCenter} style={{ width: colWidths.schoolWidth + 'px' }} >School</span>
          </div>
          <div className={classNames(styles.passportAdminReviewTableHeaderCol)}>
            <span className={styles.textCenter} style={{ width: colWidths.programWidth + 'px' }}>Program</span>
          </div>
          <div className={classNames(styles.passportAdminReviewTableHeaderCol)}>
            <span className={styles.textCenter} style={{ width: colWidths.statusWidth + 'px' }}>Status</span>
          </div>
          <div className={classNames(styles.passportAdminReviewTableHeaderCol)}>
            <span className={styles.textCenter} style={{ width: colWidths.viewWidth + 'px' }} >View</span>
          </div>
        </div>
        <div className={styles.passportAdminReviewTableContent}>
          {renderItems()}
        </div>

        <div className={styles.passportAdminReviewTableFooter} >
          <DashboardTablePagination
            onPageChange={handlePageChange}
            total={data.length}
            perPage={limit}
          />
        </div>
      </div>
    </>
  );
};

PassportAdminReviewTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    sid: PropTypes.string,
    school: PropTypes.string,
    program: PropTypes.string,
    status: PropTypes.string
  })).isRequired
};
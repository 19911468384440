import React, { useCallback, useMemo } from "react";
import { useState, useEffect } from "react";
import styles from "./MyRequirementsPage.module.css";
import { CpnwContainer } from "hooks/useCpnw";
import { HeaderSection, LinkItem } from "@cpnw/ui";
import RequirementTable from "components/RequirementTable";
import { AdminContainer } from "hooks/useAdmin";
import { format, parseISO } from "date-fns";
import { StaticRoutes } from "pages/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AccountContainer } from "hooks/useAccount";
import PassportRecordRequirementTable from "components/PassportRecordRequirementTable";
import PassportRecordRequirements from "components/PassportRecordRequirements";
import PassportRequirementReviewerView from "components/PassportRequirementReviewerView";
import { Modal } from "@cpnw/ui";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PassportUserInputView from "components/PassportUserInputView";
import { RequirementContainer } from "hooks/useRequirement";
import { ContactTypes } from "pages/enums";

export default function ReviewPage() {
  const history = useHistory();
  const { myRequirementsData, refetchMyRequirements, refetchContactNotices } =
    RequirementContainer.useContainer();
  const { takeQuiz } = CpnwContainer.useContainer();

  // API Call on mount
  useEffect(() => {
    refetchMyRequirements();
  }, []);

  const renderTypeContact = (typeContact) => {
    switch (typeContact) {
      case ContactTypes.CPNW_ADMIN:
        return 'CPNW Clinical Passport';
      case ContactTypes.HEALTHCARE:
        return 'Healthcare';
      case ContactTypes.EDUCATION:
        return 'Education';
      default:
        return '';
    }
  };

  const data = useMemo(() => {
    if (myRequirementsData && myRequirementsData.data.length > 0) {
      return myRequirementsData.data.flatMap((a) => {
        return {
          id: a.ContactRequirementId,
          reqName: a.Name,
          src: "What is this?",
          freq: a.Frequency,
          type: a.Category.Name,
          status: a.Status,
          expiration: a.ExpiresOn ? format(a.ExpiresOn, "MM/dd/yyyy") : "",
          reviewer: a.Reviewer || "",
          score: a.Score ? a.Score.Score : null,
          canView: true,
          dueDate: a.DueDate ? format(a.DueDate, "MM/dd/yyyy") : "",
          createdByTypeContact: renderTypeContact(a.CreatedByTypeContact),
        };
      });
    }
    return [];
  }, [myRequirementsData]);

  const onItemView = useCallback(
    async (item) => {
      console.log("onItemView", item);
      const requirement = myRequirementsData.data.find(
        (a) => a.ContactRequirementId == item.id
      );

      if (requirement.Category.HasModule) {
        //eLearning, open takeQuiz
        const data = await takeQuiz(requirement.Module.Id);
        const url = data.url;
        console.log("url", url);
        window.open(url, "_blank");
      } else {
        (requirement.Submissions || []).forEach(sub => {
          sub.files = (sub.Files || []).map(f=>({
            id: f.Id,
            name: f.Name,
            url: f.Url
          }))
        });

        const modalData = {
          contactRequirementId: requirement.ContactRequirementId,
          requirementName: requirement.Name,
          type: requirement.Category,
          subCategory: requirement.SubCategory,
          abbreviation: requirement.Abbr,
          requiredBy: requirement.RequiredBy.map((a) => a.FacilityName),
          frequency: requirement.Frequency,
          instructions: requirement.Instructions || "",
          status: requirement.Status || "",
          requirementFiles: (requirement.RequirementFiles || []).map((a) => ({
            id: a.Id,
            name: a.Name,
            url: a.Url,
          })),
          messages: requirement.Messages || [],
          allowWaiver: requirement.AllowWaiver,
          isWaived: requirement.IsWaived,
          score: requirement.Score ? requirement.Score.Score : null,
          submissions: requirement.Submissions,
          submission: requirement.Submissions.length > 0 ? requirement.Submissions[0] : {},
          hasLicenses: requirement.HasLicenses,
          expiration: requirement.ExpiresOn,
          reviewer: requirement.Reviewer,
          isWaived: requirement.IsWaived,
          allowWaiver: requirement.AllowWaiver,
          createdByTypeContact: renderTypeContact(requirement.CreatedByTypeContact),
        };

        setIsModalOpen(true);
        setModalContent(modalData);
      }
    },
    [myRequirementsData]
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const onSaveComplete = () =>{
    refetchContactNotices()
    refetchMyRequirements()
    setIsModalOpen(false)
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className={styles.modal_content}
        modalClassName={styles.modal_wrapper}
      >
        <div className={styles.admin_review_modal}>
          <div className={styles.close_modal_btn}>
            <FontAwesomeIcon
              icon={faXmark}
              style={{
                fontSize: "28px",
                transform: "translateY(-15px) translateX(15px)",
                color: "#808080",
                cursor: "pointer",
              }}
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          {modalContent && <PassportUserInputView requirement={modalContent} onSaveComplete={onSaveComplete} />}
        </div>
      </Modal>
      <PassportRecordRequirements tableData={data} onItemView={onItemView} />
    </>
  );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./PassportRecordRequirementReviewerItem.module.css";

import PassportRequirementReviewerView from "components/PassportRequirementReviewerView";
import { Modal } from "@cpnw/ui";

import { LinkItem } from "@cpnw/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  faCheck,
  faBan,
  faStarOfLife,
  faHourglassStart,
  faHourglassHalf,
} from "@fortawesome/free-solid-svg-icons";
import { ContactTypes } from "pages/enums";

export default function PassportRecordRequirementReviewerItem({ item, onClick }) {
  const handleIconSet = (stat) => {
    let statusToLower = stat.toLowerCase();

    if (statusToLower === "approved") {
      return (
        <FontAwesomeIcon
          icon={faCheck}
          style={{ color: "#007e8d", fontSize: "18px", marginTop: "6px" }}
        />
      );
    } else if (statusToLower === "rejected") {
      return (
        <FontAwesomeIcon
          icon={faBan}
          style={{ color: "red", fontSize: "16px", rotate: "90deg" }}
        />
      );
    } else if (statusToLower === "waiver") {
      return (
        <FontAwesomeIcon
          icon={faStarOfLife}
          style={{ color: "#aa9b73", fontSize: "16px" }}
        />
      );
    } else if (statusToLower === "expired") {
      return (
        <FontAwesomeIcon
          icon={faHourglassStart}
          style={{ color: "red", fontSize: "16px", rotate: "180deg" }}
        />
      );
    } else if (statusToLower === "expiring") {
      return (
        <FontAwesomeIcon
          icon={faHourglassHalf}
          style={{ color: "#aa9b73", fontSize: "16px" }}
        />
      );
    }
    else {
      return (
        <div>{stat}</div>
      );
    }
  };

  const renderTypeContact = (typeContact) => {
    switch (typeContact) {
      case ContactTypes.CPNW_ADMIN:
        return 'CPNW Clinical Passport';
      case ContactTypes.HEALTHCARE:
        return 'Healthcare';
      case ContactTypes.EDUCATION:
        return 'Education';
      default:
        return '';
    }
  };

  return (
    <>
      <div className={styles.record_row}>
        <div className={classNames(styles.record_item, styles.req_name)}>
          {onClick ? (
            <div onClick={(ev) => {
              if (item.canView) {
                ev.preventDefault()
                ev.stopPropagation()
                onClick(item)
              }
            }}>
              <LinkItem label={item.reqName} src="#" disabled={item.canView == false} linkClassName={item.canReview == false ? styles.red : null} />{" "}
            </div>
          ) : (
            <LinkItem label={item.reqName} src={item.src} />
          )}
        </div>
        <div className={classNames(styles.record_item, styles.icon)}>
          {/* {item.status} */}
          <div className={styles.content}>{handleIconSet(item.status)}</div>
        </div>
        <div className={classNames(styles.record_item)}>
          <p className={styles.content}>{item.reviewer}</p>
        </div>
        <div className={classNames(styles.record_item)}>
          <p className={styles.content}>{item.score}</p>
        </div>
        <div className={classNames(styles.record_item, styles.dueDate)}>
          <p className={styles.content}>{item.dueDate}</p>
        </div>
        <div className={classNames(styles.record_item)}>
          <p className={styles.content}>{item.expiration}</p>
        </div>
        {/* <div className={classNames(styles.record_item, styles.category)}>
          <p className={styles.content}>{renderTypeContact(item.createdByTypeContact)}</p>
        </div> */}
        {/* <div className={classNames(styles.record_item)}>
          <p className={styles.content}>{item.freq}</p>
        </div> */}
        {/* <div className={classNames(styles.record_item)}>
          <p className={styles.content}>{item.type}</p>
        </div> */}
      </div>
    </>
  );
}

PassportRecordRequirementReviewerItem.propTypes = {
  item: PropTypes.shape({
    reqName: PropTypes.string.isRequired,
    src: PropTypes.string,
    // freq: PropTypes.string.isRequired,
    // type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    score: PropTypes.number,
    expiration: PropTypes.string.isRequired,
    reviewer: PropTypes.string.isRequired,
    // createdByTypeContact: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

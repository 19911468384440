import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import styles from "./RequirementsPage.module.css";
import { CpnwContainer } from "hooks/useCpnw";
import { HeaderSection, LinkItem, Modal, Button } from "@cpnw/ui";
import RequirementTable from "components/RequirementTable";
import { AdminContainer } from "hooks/useAdmin";
import { format, parseISO } from "date-fns";
import { StaticRoutes } from "pages/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { RequirementContainer } from "hooks/useRequirement";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { AccountContainer } from "hooks/useAccount";
import environment from "environment";
import { CanPermissions } from "pages/enums";

export default function RequirementsPage() {
  const history = useHistory();
  const {
    adminRequirements,
    refetchAdminRequirements,
    requirementSelectboxValuesData,
    refetchRequirementSelectboxValues,
    setRequirement,
    deleteRequirement,
  } = RequirementContainer.useContainer();

  const [isLoading, setIsLoading] = useState(true);

  const [rolesLoading, setRolesLoading] = useState(true);
  const accountContainerData = AccountContainer.useContainer();

  useEffect(() => {
    if (
      accountContainerData.userRoles &&
      accountContainerData.userRoles.length > 0 
    ) {
      setRolesLoading(false);
    } else {
    }
  }, [accountContainerData.userRoles]);

  // Check if user has the required roles
  const hasRequiredRoles = useMemo(() => {
    if (!accountContainerData.userRoles) return false;
    return accountContainerData.userRoles.some((role) =>
      ["EDUCATION", "HEALTHCARE", "CPNW-ADMIN", "CPNW-REVIEWER"].includes(
        role.toUpperCase()
      )
    );
  }, [accountContainerData.userRoles]);

  
  useEffect(() => {
    if ((!rolesLoading && !hasRequiredRoles) || !accountContainerData.hasCanPermissions(CanPermissions.CanCoordinate)) {
      window.location.href = environment.homeUrl;
      return;
    }
  }, [rolesLoading, hasRequiredRoles, accountContainerData]);

  // API Call on mount

  useEffect(async () => {
    async function loadData() {
      setIsLoading(true);
      setRequirement(null);

      var requests = [];
      if (!requirementSelectboxValuesData) {
        requests = [refetchRequirementSelectboxValues()];
      }

      if (!adminRequirements) {
        requests.push(refetchAdminRequirements());
      }

      await Promise.all(requests);

      setIsLoading(false);
    }
    loadData();
  }, [adminRequirements, requirementSelectboxValuesData]);

  const data = useMemo(() => {
    if (
      adminRequirements &&
      adminRequirements.data &&
      requirementSelectboxValuesData
    ) {
      return adminRequirements.data.flatMap((a) => {
        return {
          id: a.Id,
          name: a.Name,
          type: requirementSelectboxValuesData.Categories.find(
            (rc) => rc.Id == a.CategoryId
          ).Name,
          freq: requirementSelectboxValuesData.Frequencies.find(
            (f) => f.Id == a.FrequencyId
          ).Name,
          reqBy: requirementSelectboxValuesData.Facilities.filter((rf) =>
            a.FacilityIds.includes(rf.Id)
          )
            .map((rf) => rf.Name)
            .join(", "),
          reqFor: requirementSelectboxValuesData.Disciplines.filter((rd) =>
            a.DisciplineIds.includes(rd.Id)
          )
            .map((rf) => rf.Name)
            .join(", "),
          created: format(parseISO(a.CreatedOn), "MM/dd/yyyy"),
          retired: false,
          status: requirementSelectboxValuesData.Statuses.find(
            (rsd) => rsd.Id == a.StatusId
          ).Name,
        };
      });
    }
    return [];
  }, [adminRequirements, requirementSelectboxValuesData]);

  const [idToDelete, setIdToDelete] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onDeleteRequirement = async (id) => {
    console.log("onDeleteRequirement", id);
    setIdToDelete(id);
    setIsDeleteModalOpen(true);
  };


  return (
    <section>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => {
          setIsDeleteModalOpen(false);
        }}
        className={classNames([])}
      >
        <div className={styles.warningTitle}>
          <FontAwesomeIcon icon={faWarning}></FontAwesomeIcon> This action
          cannot be reverted!
        </div>
        <div className={styles.center}>
          Do you want to delete this item '
          {data.find((a) => a.id == idToDelete)?.name}'?
        </div>
        <div className={classNames(styles.center, styles.warningButtons)}>
          <Button
            text={"YES"}
            type={"submit"}
            className={styles.customButton}
            onClick={async (ev) => {
              setIsDeleteModalOpen(false);
              setIdToDelete(0);
              setIsLoading(true);

              await deleteRequirement(idToDelete);
              await refetchAdminRequirements();

              setIsLoading(false);
            }}
          />
          <Button
            text={"NO"}
            type={"cancel"}
            className={styles.customButton}
            onClick={() => {
              setIdToDelete(0);
              setIsDeleteModalOpen(false);
            }}
          />
        </div>
      </Modal>
      <RequirementTable
        items={data || []}
        key={"1"}
        onAddRequirement={() => {
          setRequirement(null);
          history.push(StaticRoutes.RequirementForm);
        }}
        onEditRequirement={(id) => {
          setRequirement(
            JSON.parse(
              JSON.stringify(adminRequirements.data.find((a) => a.Id == id))
            )
          );
          history.push(StaticRoutes.RequirementForm);
        }}
        onDeleteRequirement={onDeleteRequirement}
        isLoading={isLoading}
      />
    </section>
  );
}
